* {
    box-sizing: border-box;
}

input,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}


#root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Wrapper {
    position: relative;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.Form {
    animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #2e2e2e;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #424242;
    border-radius: 4px;
}

.CardField {
    
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #f5f5f5;
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #f5f5f5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #ffffff;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: #9e9e9e;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}


.SubmitButton {
    display: block;
    font-size: 16px;
    width: 100%;
    height: 40px;
    margin: 40px 0 0;
    background-color: #e91e63;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ec407a;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
    background-color: #ad1457;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #c2185b;
    transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
    transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #757575;
    box-shadow: none;
}
.SubmitButtonPayPal {
    background-color: #009cde;
    box-shadow: unset;
    color: #fff;
    margin: 0;
}
.SubmitButtonPayPal--active {
    background-color: #003087;
    margin-bottom: 48px;
}
.SubmitButtonPayPal:hover {
    filter: brightness(0.95);
}
.SubmitButtonPayPal:active {
    filter: brightness(0.95);
}

.ErrorMessage {
    color: #f50057;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
}

.ResultTitle {
    color: #fff;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.ResultMessage {
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}