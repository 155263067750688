.loading {
    font-size: 1.3rem;
    text-align: left;
    min-width: 104px;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 1500ms infinite;
    animation: ellipsis steps(4,end) 1500ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}