
* {
  padding-inline-start: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

html,body {
  overflow: hidden;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  background-color: #2e2e2e;
}

.body-loaded {
  background-image: url(./background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100vh;
}

.bubblegum {
  font-family: BubbleGum !important;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* display: grid; */
  overflow: hidden;
  margin: 0;
  list-style: none;
  /* grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr); */
  /* gap: 15px;
  padding: 15px; */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}

.item {
  position: absolute;
  background: white;
  border-radius: 10px;
  width: 30px;
  height: 15px;
}
