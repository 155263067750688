.loading {
    font-size: 1.3rem;
    text-align: left;
    min-width: 104px;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 1500ms infinite;
    animation: ellipsis steps(4,end) 1500ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.MuiInput-root {
    background-color: white !important;
    min-width: 120px !important;
    text-align: center !important;
    padding: 16px;
    /*font-family: BubbleGum !important;*/
    color: #e91e63 !important;
}
.MuiInput-input {
    padding: 18px 12px 10px !important;
    font-size: 24px !important;
    text-align: center;
    font-weight: 600;
}
.MuiInput-underline {
    border: 0
}
.MuiInput-underline:after {
    border-bottom: 2px solid #e91e63 !important;
}
.MuiInput-underline:before {

    border-bottom: 0px solid #e91e63 !important;
}
